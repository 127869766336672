import React, { useEffect, useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';

import AspectBox from '../../components/AspectBox';
import Img from '../../components/Img';
import Layout from '../../components/Layout';
import { buildImgixUrlWithAspectRatio } from '../../util/images';

import './Contact.scss';

const bananaPhone = 'https://images.prismic.io/incriveisexperiencias/85c2a165-a13e-4c42-a65c-e8ec797e1077_ring-ring-ring-banana-phone.jpeg';

const Contact: React.FC = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  let qs: string | undefined;
  if (typeof window !== 'undefined') {
    qs = window.location.search;
  }

  useEffect(() => {
    if (qs === '?ok' && !isFormSubmitted) {
      setIsFormSubmitted(true);
    } else if (isFormSubmitted) setIsFormSubmitted(false);
  }, [qs]);

  return (
    <Layout mainClassName={!isFormSubmitted ? 'contact' : 'contact-ok'} title="Contato - Experiências Incríveis">
      {!isFormSubmitted && (
        <>
          <div className="contact-heading">
            <div className="container">
              <AspectBox heightRatio={1 / 0.5}>
                <Img src={buildImgixUrlWithAspectRatio(bananaPhone, 1 / 0.5, 900, { crop: 'bottom' })} />
              </AspectBox>

              <div>
                <h1>Alô?</h1>
                <h5>Fale com a gente!</h5>
              </div>
            </div>
          </div>

          <div className="container">
            <form name="Contato" data-netlify="true" action="/contato?ok" method="post">
              <input type="hidden" name="form-name" value="Contato" />

              <label>
                <span>Nome:</span>
                <input type="text" name="Nome" required />
              </label>

              <label>
                <span>E-mail: <i>opcional</i></span>
                <input type="email" name="E-mail" />
              </label>

              <label>
                <span>Telefone: <i>opcional</i></span>
                <input type="text" name="Telefone" />
              </label>

              <label>
                <span>Mensagem:</span>
                <textarea name="Mensagem" required />
              </label>

              <button type="submit">
                <FaPaperPlane />
                Enviar!
              </button>
            </form>
          </div>
        </>
      )}

      {isFormSubmitted && (
        <div className="container">
          <h2>
            <span role="img" aria-label="Emoji">😊</span>
            Enviado!
          </h2>

          <p>Responderemos a sua mensagem assim que possível!</p>
        </div>
      )}
    </Layout>
  );
};

export default Contact;
